/* eslint-disable */
import React, { Component } from 'react';
import { Button, RadioButton, FormGroup, Input } from '@samhaeng/components';
import './global.css';
import css from './App.css';

import Countdown from './components/Countdown';

const LABELS = {
  START: 'Meeting begins in',
  RESUME: 'Meeting resumes in',
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      running: false,
      other: false,
      count: 0,
      duration: 5,
      otherDuration: null,
      label: LABELS.START,
    }

    this.availableDurations = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  }

  onDurationChange = (value) => {
    this.setState({
      duration: parseFloat(value),
      otherDuration: null,
    });
  }

  handleOtherDurationChange = e => {
    this.setState({
      otherDuration: parseFloat(e.target.value),
    });
  }

  onLabelChange = ({ target: { value: label }}) => {
    this.setState({
      label
    });
  }

  startCountdown = () => {
    const { duration: fixedDuration, otherDuration } = this.state;
    const duration = otherDuration || fixedDuration;
    clearInterval(this.timeout);
    
    this.setState({
      running: true,
      count: duration * 60,
    });

    this.timeout = setInterval(this.subtractOneSecond, 1000);
  }

  subtractOneSecond = () => {
    this.setState((state) => {
      if (state.count > 0) {
        return {
          count: state.count - 1,
        }
      }

      // Stop timer
      clearTimeout(this.timeout);
      return {
        count: 0,
      }
    })
  }

  submit = (e) => {
    e.preventDefault();
    this.startCountdown();
  }

  renderControls = () => {
    const { label, duration, otherDuration } = this.state;
    return (
      <form onSubmit={this.submit} className={css.controls} method="POST">
        <div className={css.fields}>
          <FormGroup className={css.formGroup}>
            <RadioButton
              onChange={this.onLabelChange}
              name="label"
              value={LABELS.START}
              label="Start"
              checked={label === LABELS.START}
            />
            <RadioButton 
              onChange={this.onLabelChange} 
              name="label"
              value={LABELS.RESUME}
              label="Resume"
              checked={label === LABELS.RESUME} 
            />
          </FormGroup>
          <FormGroup className={css.formGroup}>
            {
              this.availableDurations.map(value => (
                <RadioButton
                  key={value}
                  onChange={e => this.onDurationChange(e.target.value)}
                  value={value}
                  name="duration"
                  label={`${value} minute${value > 1 ? 's' : ''}`}
                  checked={duration === value} 
                />
              ))
            }
            <RadioButton
              className={css.otherRadioButton}
              onChange={() => this.onDurationChange(0)}
              value="other"
              name="duration"
              label="Other"
              checked={this.availableDurations.indexOf(duration) < 0} 
            />
            {
              (this.availableDurations.indexOf(duration) < 0) && (
                <Input 
                  className={css.otherField}
                  onChange={this.handleOtherDurationChange}
                  value={otherDuration}
                  autoFocus
                  type="number"
                  id="other-duration"
                  placeholder="Enter value"
                />
              )
            }
          </FormGroup>
        </div>
        <Button type="submit">
          Go
        </Button>
      </form>
    )
  }

  render() {
    const { running, count, label } = this.state;
    return (
      <div className={css.root}>
        { running ? <Countdown count={count} label={label} /> : this.renderControls() }
      </div>
    );
  }
}

export default App;
