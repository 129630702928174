/**
 * Countdown
 */
/* eslint-disable */
import React from 'react';
import moment from 'moment';
import { Headline } from '@samhaeng/components';
import css from './Countdown.css';

const Countdown = ({ count, label }) => {
  const duration = moment.duration(count, 'seconds');
  
  const formatNumber = number => {
    if (number.toString().length === 1) {
      return `0${number}`;
    }
    return number;
  }
  console.log(duration.minutes() + ' : ' + duration.seconds());

  return (
    <div className={css.root}>
      <Headline size="large" className={css.title} contenteditable="true">
        {label}
      </Headline>
      <div className={css.numbers}>
        <div className={css.number}>
          { formatNumber(duration.minutes()) }
        </div>
        <div className={css.dot} />
        <div className={css.number}>
          { formatNumber(duration.seconds()) }
        </div>
      </div>
    </div>
  );
}

export default Countdown;
